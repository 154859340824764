import {Service} from "./services";

export interface Order {
    id: number;
    job_id: number;
    subscription_order_id: number;
    category_name: string;
    category_thumb: string;
    schedule_date: string;
    served_date: string;
    process_date: string;
    cancelled_date: string;
    schedule_date_readable: string;
    preferred_time: string;
    readable_status: string;
    status: string;
    is_on_premise: number;
    customer_favorite: string;
    isRentCar: number;
    status_color: string;
    delivery_address: string;
    delivery_name: string;
    delivery_mobile: string;
    partner_name: string;
    partner_logo: string;
    resource_name: string;
    resource_pic: string;
    contact_number: string;
    contact_person: string;
    rating: number;
    price: number;
    order_code: string;
    created_at: string;
    created_at_timestamp: number;
    version: string;
    original_price: number;
    discount: number;
    discounted_price: number;
    complain_count: number;
    can_pay: boolean;
    can_take_review: boolean;
    jobs: Order[];
}

export class OrderServiceConfig {
    mainConfig: string;
}

export class TenderServiceConfig {
    mainConfig: string;
}

export interface OrderPlaceObject {
    services: Service[];
    partner: number;
    time: string;
    category: number;
    date: string;
    mobile: string;
}

export interface OrderDetails {
    id: number;
    order_id: number;
    partner_id: number;
    discount: string;
    discount_percent: string|number;
    sheba_collection: string;
    partner_collection: string;
    refund_amount: string;
    is_reconciled: number;
    invoice: string|number;
    partner_searched_count: number;
    partners_for_sp_assign: string;
    payment_method: string;
    collected_by: string;
    closed_at: string;
    closed_and_paid_at: string;
    cancelled_at: string;
    finance_collection: string;
    finance_cm_cleared_at: string;
    finance_closed_at: string;
    created_at: string;
    total_paid: number;
    total_due: number;
    total_price: number;
    delivery_name: string;
    delivery_mobile: string;
    delivery_address: string;
    jobs: Jobs[];
}

export interface Jobs {
    id: number;
    job_id: number;
    subscription_order_id: string|number;
    category_name: string;
    category_thumb: string;
    schedule_date: string;
    schedule_date_for_b2b: string;
    served_date: string;
    process_date: string;
    cancelled_date: string;
    schedule_date_readable: string;
    preferred_time: string;
    readable_status: string;
    status: string;
    is_on_premise: number;
    customer_favorite: string;
    isRentCar: number;
    status_color: string;
    partner_name: string;
    partner_logo: string;
    partner_mobile_number: string;
    partner_total_rating: number;
    partner_avg_rating: number;
    resource_name: string;
    resource_pic: string;
    resource_mobile_number: string;
    resource_total_rating: string|number;
    resource_avg_rating: string|number;
    contact_number: string;
    contact_person: string;
    rating: string|number;
    price: number;
    order_code: string;
    created_at: string;
    created_at_timestamp: number;
    version: string;
    original_price: number;
    discount: number;
    discounted_price: number;
    complain_count: number;
    message: {
        status: string;
        log: string;
        type: string;
    };
}

export interface OrderBill {
    total: number;
    total_without_logistic: number;
    original_price: number;
    paid: number;
    due: number;
    material_price: number;
    total_service_price: number;
    discount: number;
    services: OrderedService[];
    service_list: OrderedServiceList[];
    category_name: string;
    delivered_date: string;
    delivered_date_timestamp: string|number;
    closed_and_paid_at: string;
    closed_and_paid_at_timestamp: string|number;
    payment_method: string;
    status: string;
    isRentCar: number;
    is_on_premise: number;
    delivery_charge: number;
    delivery_discount: number;
    invoice: string|number;
    version: string;
    voucher: string|number;
    is_vat_applicable: number;
    is_closed: number;
    max_order_amount: number;
    pick_up: string;
    destination: string;
    is_surcharge_applied: number;
    surcharge_percentage: number;
    surcharge_amount: number;
}

export interface OrderedService {
    name: string;
    quantity: number;
    job_service_id: number;
    service_id: number;
    price: number;
    min_price: number;
    is_min_price_applied: number;
}

export interface OrderedServiceList {
    id: number;
    name: string;
    service_group: any[]; // Check Type Later
    unit: string|number;
    quantity: number;
    price: number;
}
