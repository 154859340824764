import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanLoad, Resolve, Route, Router, RouterStateSnapshot, UrlSegment} from '@angular/router';
import {BehaviorSubject, Observable} from 'rxjs';
import {ShebaHttpService} from '../../modules/sheba-http/sheba-http.service';
import {StorageService} from '../../services/storage.service';
import {of} from 'rxjs/internal/observable/of';
import {ProfileService} from '../../services/profile.service';
import {PopAlertService} from '../../modules/pop-alert/pop-alert.service';

@Injectable({
    providedIn: 'root'
})

export class DashboardResolveService implements Resolve<Observable<any>>, CanLoad {

    // tslint:disable-next-line:variable-name
    public DashboardBehavior: BehaviorSubject<{ open: boolean }> = new BehaviorSubject({open: true});

    constructor(private http: ShebaHttpService,
                private storage: StorageService,
                private router: Router,
                private profileService: ProfileService,
                private pop: PopAlertService) { }

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<Observable<any>> | Promise<Observable<any>> | Observable<any> {
        if (route.queryParams.refresh_token && route.queryParams.refresh_token === '1') {
            return this.refreshToken();
        } else {
            this.setProfile();
            return this.getBusinessInfo().toPromise();
        }
    }

    async refreshToken() {
        const url = `/v2/businesses/${this.storage.user.business_id}/refresh-token?token=${this.storage.user.token}`;
        const jwt_token =  await this.http.get(url).toPromise().then(res => res.code === 200 ? res.token : null);
        if (jwt_token) {
            this.storage.user.token = jwt_token;
            this.setProfile();
            return this.getBusinessInfo().toPromise();
        } else {
            this.storage.removeData();
        }
    }

    setProfile() {
        if (this.storage.user) {
            return this.profileService.getProfile().toPromise().then(res => {
                if (res.code === 420) {
                    this.handleProfile420Error(res);
                    return;
                }
                if (res.code === 200) {
                    this.profileService.setProfileToStorage(res);
                    return;
                }
                console.error(res.message);
            }).catch(err => console.error(err.message));
        } else {
            return of(null);
        }
    }

    handleProfile420Error(res) {
        this.storage.removeData();
        this.router.navigate(['/']).catch(err => console.error(err));
        this.pop.open(res.message);
    }

    getBusinessInfo() {
        if (this.storage.user) {
            return this.profileService.getBusinessInfo();
        } else {
            return of(null);
        }
    }

    canLoad(route: Route, segments: UrlSegment[]): Observable<boolean> | Promise<boolean> | boolean {
        const accessAuth = segments.length && segments[0].path === 'auth';
        const accessDashboard = segments.length && segments[0].path === 'dashboard';

        if (this.storage.user && accessAuth) {
            this.router.navigate(['/', 'dashboard']).then(() => {
                this.pop.open('You are already logged in');
            });
        }
        if (!this.storage.user && accessDashboard) {
            this.router.navigate(['/', 'auth']).then(() => {
                this.pop.open('You are not authorized to view this resource. Please login first');
            });
        }

        return true;
    }
}
