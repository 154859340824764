import {ModuleWithProviders, NgModule} from '@angular/core';
import {FacebookPixelEvent} from './services/facebook-pixel-event';
import {CheckSalaryAlertTriggeredService} from './services/check-salary-alert-triggered.service';

@NgModule({
    imports: [],
    exports: [],
})

export class CoreModule {
    static forRoot(): ModuleWithProviders<CoreModule> {
        return {
            ngModule: CoreModule,
            providers: [FacebookPixelEvent, CheckSalaryAlertTriggeredService]
        };
    }
}
