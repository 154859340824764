import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-pop-alert-success',
  templateUrl: './pop-alert-success.component.html',
  styleUrls: ['./pop-alert-success.component.scss']
})
export class PopAlertSuccessComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
