import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of } from 'rxjs';
import {filter, switchMap, take, tap} from 'rxjs/operators';
import { ShebaHttpConfig } from './http.conf';
import { StorageService } from '../../services/storage.service';
import { PopAlertService } from '../pop-alert/pop-alert.service';
import { JwtService } from '../../services/jwt.service';
import {HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse} from '@angular/common/http';
import {Router} from '@angular/router';

@Injectable()

export class ShebaHttpInjectorService implements HttpInterceptor {

    cachedRequests: Array<HttpRequest<any>> = [];

    constructor(
        private storage: StorageService,
        private config: ShebaHttpConfig,
        private pop: PopAlertService,
        private router: Router
    ) {
    }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        const user: any = this.storage.user;
        const addToken = !req.urlWithParams.includes('token');
        const token = user ? user.remember_token ? user.remember_token : user.token : null;
        if (token && !req.url.includes('token=') && addToken) {
            req = req.clone({
                setHeaders: {
                    Authorization: `Bearer ${user.token}`,
                    'portal-name': 'business-portal'
                },
                setParams: {
                    token: user.token
                }
            });
        }
        return next.handle(req).pipe(tap((event: HttpEvent<any>) => {
            if (event instanceof HttpResponse) {
                if (event.body.code === 401 && token) {
                    this.router.navigate(['/']).catch(err => {
                        console.log(err);
                    });
                    this.storage.removeData();
                    this.pop.open('Your Session has expired ');
                }
            }
        }, (err: any) => {
            if (err instanceof HttpErrorResponse) {
                if (err.status === 401) {
                    this.collectFailedRequest(req);
                } else if (err.status === 0 && err.message.match('Http failure response')) {
                    this.pop.open('No internet connection. Please check your internet connection or try again later');
                }
            } else {
                // tslint:disable-next-line:no-shadowed-variable
                this.router.navigate(['/']).catch(err => {
                    console.log(err);
                });
            }
        }));
    }

    public collectFailedRequest(request): void {
        this.cachedRequests.push(request);
    }


















    // cachedRequests: Array<HttpRequest<any>> = [];
    //
    // private isRefreshing = false;
    // private refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);
    //
    // constructor(private storage: StorageService,
    //             private config: ShebaHttpConfig,
    //             private pop: PopAlertService,
    //             private jwtService: JwtService) { }
    //
    // intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    //     const user: any = this.storage.user;
    //     const addToken = !req.urlWithParams.includes('token');
    //     const token = user ? user.token : null;
    //
    //     if (token && !req.url.includes('token=') && addToken) {
    //         req = this.addToken(req, user.token);
    //     }
    //
    //     return next.handle(req).pipe(switchMap((event) => {
    //         if (event instanceof HttpResponse && event.body.code === 401 && token) {
    //             return this.handle401Error(req, next);
    //         }
    //         return of(event);
    //     }));
    // }
    //
    // public collectFailedRequest(request): void {
    //     this.cachedRequests.push(request);
    // }
    //
    // private addToken(request: HttpRequest<any>, token: string) {
    //     return request.clone({
    //         setHeaders: {
    //             Authorization: `Bearer ${token}`,
    //         },
    //         setParams: {
    //             token
    //         }
    //     });
    // }
    //
    // private handle401Error(request: HttpRequest<any>, next: HttpHandler) {
    //     if (!this.isRefreshing) {
    //         this.isRefreshing = true;
    //         this.refreshTokenSubject.next(null);
    //
    //         return this.jwtService.getRefreshedJWT().pipe(
    //             switchMap((res: any) => {
    //                 this.isRefreshing = false;
    //                 this.refreshTokenSubject.next(res.token);
    //                 return next.handle(this.addToken(request, res.token));
    //             }));
    //
    //     } else {
    //         console.log('is refreshing');
    //         return this.refreshTokenSubject.pipe(
    //             filter((res) => res != null),
    //             take(1),
    //             switchMap((res) => {
    //                 return next.handle(this.addToken(request, res));
    //             }));
    //     }
    // }
}
