import { ErrorHandler} from '@angular/core';
import { environment } from '../../../environments/environment';
import * as Sentry from '@sentry/browser';
import {PopAlertService} from '../pop-alert/pop-alert.service';

export class SentryErrorHandler implements ErrorHandler {
    constructor(private pop?: PopAlertService) {
        Sentry.init({
            dsn: environment.sentry_key,
            ignoreErrors: [
                'ResizeObserver loop limit exceeded',
                'Non-Error exception captured'
            ],
        });
    }

    handleError(error) {
        const chunkFailedMessage = /Loading chunk [\d]+ failed/;

        if (chunkFailedMessage.test(error.message)) {
            return;
        }

        const exception = error.error || error.message || error.originalError || error;
        Sentry.captureException(exception);
    }
}


export function getErrorHandler(): ErrorHandler {
    if (environment.production) {
        return new SentryErrorHandler();
    }
    return new ErrorHandler();
}
