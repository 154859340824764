import { Injectable } from '@angular/core';

@Injectable()
export class CheckSalaryAlertTriggeredService {

  constructor() {
      this.setCookie();
  }

    setCookie() {
        localStorage.setItem('alreadyShowed', JSON.stringify('false'));
    }

    setCookieAlertModalShowed() {
      localStorage.setItem('alreadyShowed', JSON.stringify('true'));
    }

    getCookie() {
        return localStorage.getItem('alreadyShowed') === JSON.stringify('false');
    }
}
