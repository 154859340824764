import {Inject, Injectable} from '@angular/core';
import {environment} from '../../../environments/environment';
declare let fbq: any;

@Injectable()
export class FacebookPixelEvent {

    constructor() {
    }

    initializePixel(): void {
        {
            if (environment.FbPixelId) {
                const pixelScript = document.createElement('script');
                pixelScript.innerHTML = `
        !function(f,b,e,v,n,t,s)
        {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
        n.callMethod.apply(n,arguments):n.queue.push(arguments)};
        if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
        n.queue=[];t=b.createElement(e);t.async=!0;
        t.src=v;s=b.getElementsByTagName(e)[0];
        s.parentNode.insertBefore(t,s)} (window, document,'script',
        'https://connect.facebook.net/en_US/fbevents.js');
        fbq('init', '${environment.FbPixelId}');
    `;
                document.head.appendChild(pixelScript);
            }

        }
    }

    event(action: string, eventName: string, payload?: any): void {
        fbq(action, eventName, payload);
    }
}

// Usage

// const eventPayload = {
//     Name: this.user?.DisplayName,
//     BulletName: data?.Name,
//     MobileNumber: this.user?.PhoneNumber,
//     Area: this.userCurrentLocation?.Zone.Name,
//     CTSource: 'SuperApp'
// };

// this.fbPixelService.event('track','[F] Bullet Clicked',eventPayload);
