import { Component, Renderer2, Inject, isDevMode, OnInit } from '@angular/core';
import { NavigationCancel, NavigationEnd, NavigationError, NavigationStart, Router } from '@angular/router';
import { StorageService } from './services/storage.service';
import { SwRegisterService } from './sw-register.service';
import { WINDOW } from '@ng-toolkit/universal';
import { DOCUMENT } from '@angular/common';
import { environment } from '../environments/environment';
import { FacebookService, InitParams } from 'ngx-facebook';
import {FacebookPixelEvent} from "./core/services/facebook-pixel-event";

declare const $crisp: any;
declare const FB: any;

// import io from 'socket.io-client';
// const socket = io('https://www.dev-sheba.xyz:3000');


@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})

export class AppComponent implements OnInit {

    title = 'Sheba B2B Frontend';
    loading: boolean;
    facebookMessengerAppId = environment.facebook_messenger_app_id;
    facebookPageId = environment.facebook_page_id;

    constructor(private router: Router,
                private storage: StorageService,
                private renderer2: Renderer2,
                private facebookService: FacebookService,
                private sWRegisterService: SwRegisterService,
                @Inject(WINDOW) private window,
                @Inject(DOCUMENT) private document,
                private facebookPixelEvent: FacebookPixelEvent) {
    }

    ngOnInit() {
        this.initFacebookService();
        // this.watchUserStorage();
        this.renderConsoleWarning();
        this.routerEventsForProgressBar();
        this.integrateGoogleAnalytics();
        this.registerServiceWorker();
        this.facebookPixelEvent.initializePixel();
    }

    private initFacebookService(): void {
        const initParams: InitParams = { appId: this.facebookMessengerAppId, xfbml: true, version: 'v2.2'};
        this.facebookService.init(initParams)
            .then((response) => console.log('Response: ', response))
            .catch((error) => console.error('Error: ', error));
    }

    // watchUserStorage() {
    //     this.storage.userStorageWatcher.subscribe(user => {
    //         if (user) {
    //             //     if (user.name) {
    //             //         this.window.$crisp.push(['set', 'user:nickname', user.name]);
    //             //     }
    //             //     this.window.$crisp.push(['set', 'user:email', user.email]);
    //             //     this.window.$crisp.push(['set', 'user:avatar', user.pro_pic]);
    //         }
    //     });
    // }

    routerEventsForProgressBar() {
        this.router.events.subscribe(res => {
            if (res instanceof NavigationStart) {
                this.loading = true;
            }
            if (res instanceof NavigationEnd) {
                this.loading = false;
                this.window.scrollTo({top: 0, behavior: 'auto'});
            }
            if (res instanceof NavigationError) {
                this.loading = false;
            }
            if (res instanceof NavigationCancel) {
                this.loading = false;
            }
        });
    }

    registerServiceWorker() {
        if (this.storage.user !== null) {
            this.sWRegisterService.registerServiceWorker();
        }

    }

    renderConsoleWarning() {
        if (!isDevMode()) {
            console.log('%cPlease Stop!', 'color: red; font-family: sans-serif; font-size: 4.5em; font-weight: bolder; text-shadow: #000 1px 1px;');
            console.log('%cThis is a browser feature intended for developers. If someone told you to copy and paste something here to enable a  feature or "hack" ' +
                'someone\'s account, it is a scam and will give them access to your account.', 'color: #444; font-family: sans-serif; font-size: 1.5em; font-weight: medium;');
        }
    }

    integrateGoogleAnalytics() {
        const gaId = `'${environment.google_analytics_id}'`;

        const code = `
            (function(i,s,o,g,r,a,m){i['GoogleAnalyticsObject']=r;i[r]=i[r]||function(){
                (i[r].q=i[r].q||[]).push(arguments)},i[r].l=1*new Date();a=s.createElement(o),
                m=s.getElementsByTagName(o)[0];a.async=1;a.src=g;m.parentNode.insertBefore(a,m)
            })(window,document,'script','https://www.google-analytics.com/analytics.js','ga');

            window.ga = ga;

            ga('create', ${gaId}, 'auto');  // <- add the UA-ID
            ga('send', 'pageview');
        `;

        this.renderScript(code);
    }

    integrateAmplitude() {
        const amplitudeId = `'${environment.amplitude_id}'`;

        // sha384-d/yhnowERvm+7eCU79T/bYjOiMmq4F11ElWYLmt0ktvYEVgqLDazh4+gW9CKMpYW

        const code = `
           (function(e, t) {
            var n = e.amplitude || {
                _q: [],
                _iq: {}
            };
            var r = t.createElement("script");
            r.type = "text/javascript";
            r.integrity = "";
            r.crossOrigin = "anonymous";
            r.async = true;
            r.src = "https://cdn.amplitude.com/libs/amplitude-5.7.0-min.gz.js";
            r.onload = function() {
                if (!e.amplitude.runQueuedFunctions) {
                    console.log("[Amplitude] Error: could not load SDK")
                }
            };
            var i = t.getElementsByTagName("script")[0];
            i.parentNode.insertBefore(r, i);

            function s(e, t) {
                e.prototype[t] = function() {
                    this._q.push([t].concat(Array.prototype.slice.call(arguments, 0)));
                    return this
                }
            }
            var o = function() {
                this._q = [];
                return this
            };
            var a = ["add", "append", "clearAll", "prepend", "set", "setOnce", "unset"];
            for (var u = 0; u < a.length; u++) {
                s(o, a[u])
            }
            n.Identify = o;
            var c = function() {
                this._q = [];
                return this
            };
            var l = ["setProductId", "setQuantity", "setPrice", "setRevenueType", "setEventProperties"];
            for (var p = 0; p < l.length; p++) {
                s(c, l[p])
            }
            n.Revenue = c;
            var d = ["init", "logEvent", "logRevenue", "setUserId", "setUserProperties", "setOptOut", "setVersionName", "setDomain", "setDeviceId", "setGlobalUserProperties", "identify", "clearUserProperties", "setGroup", "logRevenueV2", "regenerateDeviceId", "groupIdentify", "onInit", "logEventWithTimestamp", "logEventWithGroups", "setSessionId", "resetSessionId"];

            function v(e) {
                function t(t) {
                    e[t] = function() {
                        e._q.push([t].concat(Array.prototype.slice.call(arguments, 0)))
                    }
                }
                for (var n = 0; n < d.length; n++) {
                    t(d[n])
                }
            }
            v(n);
            n.getInstance = function(e) {
                e = (!e || e.length === 0 ? "$default_instance" : e).toLowerCase();
                if (!n._iq.hasOwnProperty(e)) {
                    n._iq[e] = {
                        _q: []
                    };
                    v(n._iq[e])
                }
                return n._iq[e]
            };
            e.amplitude = n
        })(window, document);

        amplitude.getInstance().init("5cacbcd61dd45142ff62179c7325a138");
        `;
        this.renderScript(code);
    }

    // integrateCrispChat() {
    //     const scriptText = `
    //         window.$crisp = [];
    //         window.CRISP_WEBSITE_ID="${environment.crisp_id}";
    //         (function(){
    //             d=document;
    //             s=d.createElement("script");
    //             s.src="https://client.crisp.chat/l.js";
    //             s.async=1;
    //             d.getElementsByTagName("head")[0].appendChild(s);
    //         })();
    //     `;
    //     this.renderScript(scriptText);
    // }

    // integrateFacebookSdk() {
    //     const scriptText = `
    //         (function(d, s, id){
    //           var js, fjs = d.getElementsByTagName(s)[0];
    //           if (d.getElementById(id)) {return;}
    //           js = d.createElement(s); js.id = id;
    //           js.src = "https://connect.facebook.net/en_US/sdk.js";
    //           fjs.parentNode.insertBefore(js, fjs);
    //         }(document, 'script', 'facebook-jssdk'));`;
    //     this.renderScript(scriptText);
    // }

    // integrateFacebookCustomerChat() {
    //     const scriptText = `
    //     (function(d, s, id) {
    //       var js, fjs = d.getElementsByTagName(s)[0];
    //       if (d.getElementById(id)) return;
    //       js = d.createElement(s); js.id = id;
    //       js.src = "https://connect.facebook.net/en_US/sdk/xfbml.customerchat.js";
    //       fjs.parentNode.insertBefore(js, fjs);
    //     }(document, 'script', 'facebook-jssdk'));`;
    //     this.renderScript(scriptText);
    // }

    renderScript(code: string) {
        const s = this.renderer2.createElement('script');
        s.type = 'text/javascript';
        s.text = code;
        this.renderer2.appendChild(this.document.body, s);
    }

}
